<template>
  <div class='lable-aggregate' @touchmove='Tmove'>
    <!--  头部  -->
    <news-head @on-click-left="onClickLeft" @on-click-right="onClickRight"></news-head>
    <div class='label-title'>
      <img src='https://cdn.9kd.com/kdnet/label-list.png' alt=''>
      {{ $route.params.id }}
    </div>
    <div class='label-num'>
      <span v-if='labelInfoData.count'>{{ labelInfoData.count }}篇内容·</span>
      {{labelInfoData.views}}次浏览
    </div>
    <div class="label-introduction" v-if="labelInfoData.instruction">导语：{{labelInfoData.instruction}}</div>
    <div class='lable-line'></div>
    <!--  标签聚合页  -->
    <div class='tab'>
      <div :class='["wrap", {"wrapfix": fixedtop}]'>
        <span :class='["common", {"active": active === 1}]' @click='changTab(1)'>最热</span>
        <span :class='["common", {"active": active === 2}]' @click='changTab(2)'>最新</span>
      </div>
      <van-swipe ref='myswipe' class="my-swipe" 
      :show-indicators='false' 
      :loop='false' 
      :initial-swipe="initialSwipe"
      @change='mychange'>
        <van-swipe-item>
          <van-list
            v-model="loading1"
            :finished="finished1"
            finished-text="没有更多了"
            @load="onLoad1"
            offset='100'
          >
            <div class='lable-item-wrap'>
              <label-list-item 
                v-for="item in leftData1" 
                :key="item.articleId" 
                :dataInfo="item"
                :labelName="$route.params.id"
                @openShare="getShareInfo"
                @toDetail="toDetail">
              </label-list-item>
            </div>
          </van-list>
        </van-swipe-item>
        <van-swipe-item>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            offset='100'
          >
            <div class='lable-item-wrap'>
              <label-list-item 
                v-for="item in leftData" 
                :key="item.articleId" 
                :dataInfo="item"
                :labelName="$route.params.id"
                @openShare="getShareInfo"
                @toDetail="toDetail">
              </label-list-item>
            </div>
          </van-list>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--  App内下载  -->
    <div class='lablebtn' @click='onClickRight'>App内打开</div>

    <!-- 弹出层 -->
    <van-share-sheet @select="selectTag" v-model="showShare" :options="options" />
  </div>
</template>

<script>
import Bus from '@/utils/bus'
export default {
  name: 'LabelAggregatePage',
  components: {
    'news-head': () => import(/* webpackChunkName: "note" */ '../../components/NewsHead.vue'),
    // 'LabelCommon': () => import(/* webpackChunkName: "note" */ '../../components/LabelCommon'),
    'LabelListItem': () => import(/* webpackChunkName: "note" */ '../../components/LabelListItem'),
    
  },
  data() {
    return {
      active: 1,
      fixedtop: false, // 控制样式吸顶
      labelInfo: {  // 标签查询的参数
        sortType: 'new', // hot 最热 new 最新 默认传new
        labelName: encodeURIComponent(this.$route.params.id), // 标签名称
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      leftData: [], // 左边的数据
      labelInfo1: {  // 标签查询的参数
        sortType: 'hot', // hot 最热 new 最新 默认传new
        labelName: encodeURIComponent(this.$route.params.id), // 标签名称
        pageNo: 1,
        pageSize: 10
      },
      loading1: false,
      finished1: false,
      leftData1: [], // 左边的数据
      labelInfoData:{
        count: 0,
        instruction: "",
        name: "",
        views: 0
      },
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' },
          { name: '微博', icon: 'weibo' }
        ]
      ],
      showShare: false,
      shareInfo: null,
      initialSwipe: 0
    }
  },
  beforeRouteLeave(to, from, next) {
    // 在进入详情之后返回，最新最热数据不对
    this.initialSwipe = this.active - 1
    next()
  },
  mounted() {
    this.getLabelInfo()
    // 微信二次分享
    let labelName = this.$route.params.id || '';
    this.setWeChatShare({
      url: window.location.href,
      title: '【凯迪网话题】' + labelName,
      description: '好友分享给你一个热门话题，快来看看吧',
      friendImg: this.$baseInfo.LOGO_IMG,
      momentsImg: this.$baseInfo.MOMENTS_LOGO_IMG
    });
  },
  methods: {
    async getLeftData(n) {
      let params = n ? this.labelInfo : this.labelInfo1
      let { data: res } = await this.$api.labelAggregation(params)
      if(n) {
        if(res && res.data){
           this.leftData = this.labelInfo.pageNo == 1 ? res.data.list : this.leftData.concat(res.data.list)
        }
        // 加载状态结束
        this.loading = false;
        if(!res.data.isNextPage) {
          this.finished = true
        }
        this.labelInfo.pageNo++
      }else {
        if(res && res.data){
          this.leftData1 = this.labelInfo.pageNo == 1 ? res.data.list : this.leftData1.concat(res.data.list)
        }
        // 加载状态结束
        this.loading1 = false;
        if(res && res.data && !res.data.isNextPage) {
          this.finished1 = true
        }
        this.labelInfo1.pageNo++
      }
    },
    async onLoad() {
      this.getLeftData(true)
    },
    async onLoad1() {
      this.getLeftData(false)
    },
    onClickLeft() {
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, false)
      this.$router.push({
        path: '/topic'
      })
    },
    // 打开App选择器
    onClickRight() {
      let noteInfo = {labelName: this.$route.params.id,sortType: this.active === 1 ? 'new' : 'hot'}
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo, continuteType: Bus.LABEL_DETAIL });
    },
    /* tab按钮最新、最热的切换 */
    changTab(n) {
      if (this.active === n) return // 提升性能
      n === 2 && this.$refs.myswipe.next()
      n === 1 && this.$refs.myswipe.prev()
      this.active = n;
    },
    /* 页面滑动切换 */
    mychange(n) {
      if (n) {
        this.active = 2
      } else {
        this.active = 1
      }
    },
    /* 手指滑动，吸顶 */
    Tmove(e) {
      let distance = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      this.fixedtop = distance >= 114 ? true : false
    },
    /**
     * 获取标签相关数据
     */
    getLabelInfo(){
      this.$api.getLabelInfo({labelName:this.$route.params.id}).then(result=>{
        let res = result.data;
        if(res.code == 200) {
          this.labelInfoData = res.data;
          let views = 0;
          if(res.data.views > 0) {
            views = this.labelInfoData.views >= 10000 ? parseFloat(this.labelInfoData.views/10000).toFixed(1) + '万' : this.labelInfoData.views
          }
          this.labelInfoData.views = views;
        }
      }).catch(err=>{})
    },
    toDetail(dataInfo, index) {
      if(dataInfo.type == 10) {
        this.$router.push({
          path: '/moment/' + dataInfo.code,
          query:{
            index
          }
        });
      } else {
        this.$router.push('/note/' + dataInfo.articleId)
      }
    },
    getShareInfo(info) {
      this.showShare = true
      this.shareInfo = info
    },
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone()
      } else if (tag.name === '微博') {
        this.weiShare()
      } else {
        this.$toast({
          type: 'html',
          message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">篇文章分享出去</p>'
        })
      }
      this.showShare = false
    },
    qzone() {
      let url = 'https://' + location.hostname + '/lableaggregatepage/' + this.$route.params.id
      var shareqqzonestring = 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url + 
      '&title=' + this.shareInfo.title + '&desc=' + '【分享来之凯迪网@凯迪网】' + '&summary=&site=&pics='
      window.open(shareqqzonestring)
    },

    // 微信分享
    weiShare() {
      let url = 'https://' + location.hostname + '/lableaggregatepage/' + this.$route.params.id
      var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=' + '凯迪网' + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + ''
      window.open(sharesinastring);
    },
  }
}
</script>

<style scoped lang='less'>
.lable-aggregate {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 0px;
  .label-title {
    img {
      width: 20px;
      height: 20px;
    }
    padding: 5px 16px;
    font-size: 20px;
    font-weight: bold;
    color: #222;
    line-height: 28px;
  }
  .label-num {
    padding: 0px 16px;
    font-size: 13px;
    color: #999;
    line-height: 18px;
  }
  .label-introduction{
    padding: 12px 16px 0 16px;
    font-size: 15px;
    color: #666;
    word-break: break-all;
  }
  .lable-line {
    width: 375px;
    height: 1px;
    background: #F0EDED;
    margin: 16px 0;
  }
  .tab {
    padding: 0px 16px;
    .wrap {
      display: flex;
      margin-bottom: 16px;
      .common {
        font-size: 15px;
        color: #999;
        margin-right: 24px;
      }
      .active {
        font-size: 17px;
        font-weight: 500;
        color: #222;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 2px;
          border-radius: 1px;
          margin: 0 auto;
          background: #F7321C;
        }
      }
    }
    .wrapfix {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 0px 16px;
      background: #fff;
      border-bottom: 1px solid #F0EDED;
      z-index: 100;
    }
    .lable-item-wrap {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // .lable-item{
      //   width: 49%;
      // }
    }
  }
  .lablebtn {
    position: fixed;
    bottom: 36px;
    left: 50%;
    width: 126px;
    transform: translateX(-50%);
    height: 44px;
    line-height: 44px;
    background: #F7321C;
    box-shadow: 0px 1px 4px 0px rgba(247, 50, 28, 0.4);
    border-radius: 22px;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }
}
</style>
